import { useTheme } from 'vuetify';
import { get as lo_get } from 'es-toolkit/compat';
import type { SectionStyle, ThemeType } from '~/types';
import type { WatchStopHandle } from 'vue';
// import { useKookies } from '~/composables';

const colorModeCookieName = 'color_mode';
const resolveThemeName = (val: string) => val === 'dark' ? 'dark' : 'light';

export function useCustomThemes() {
    const $config = useRuntimeConfig();
    const useThemeObj = useTheme();

    // NOTE: colorMode Disabled
    // const colorModeObj = useColorMode();
    const colorModeObj = ref<string>('light');
    
    // const { getKookie, setKookie } = useKookies();

    let unWatchConfigs: WatchStopHandle | undefined;

    const colorModeThemeName: ComputedRef<string> = computed<string>(
        () => resolveThemeName(colorModeObj.value)
    );

    const isCurrentThemeDark: ComputedRef<boolean> = computed<boolean>(() => useThemeObj.current?.value?.dark);

    const headerConfig = ref<SectionStyle['light'|'dark']>();
    const footerConfig = ref<SectionStyle['light'|'dark']>();

    function initLayout(): void {
        // const t = getPersistedTheme() || colorModeThemeName.value;
        const t = colorModeThemeName.value;

        // Set the theme
        changeTheme(t);

        // Start Watching for changes to colorMode
        unWatchConfigs = watch(() => colorModeObj.value, (newVal) => changeTheme(newVal));
    }

    function changeTheme(themeName: string): void {
        const c = resolveThemeName(themeName);
        headerConfig.value = lo_get($config, ['public', 'theme', 'header', c]);
        footerConfig.value = lo_get($config, ['public', 'theme', 'footer', c]);
        useThemeObj.global.name.value = c;
        persistTheme(c);
    }

    function persistTheme(themeName: string): void {
        // const existingTheme = getKookie(colorModeCookieName);
        // if (existingTheme !== themeName) {
        //     setKookie(colorModeCookieName, themeName, { maxAge: longMaxAge });
        // }
    }

    function getPersistedTheme(): string | null {
        // return getKookie(colorModeCookieName) || null;
        return colorModeThemeName.value;
    }

    onBeforeUnmount(() => {
        unWatchConfigs?.();
    });

    return {
        initLayout,
        colorModeThemeName,
        changeTheme,
        persistTheme,
        getPersistedTheme,
        headerConfig,
        footerConfig,
        isCurrentThemeDark
    };
}
